
export
function lockEditLock($content: JQuery, $attachPoint?: JQuery) {
  const $editLock = $content.find('.edit-locks.alert');
  if ($editLock.length > 0 || $content.is('.edit-locks.alert')) {
    // form is locked
    const $container = $attachPoint || $content.find('.form.content');
    $container.attr('style', 'position: relative; padding: 5px;');
    $container.append($('<div class="ajax-form-blocker"></div>'));
    return true;
  }
  return false;
}

$(() => {
  $('[data-trigger="edit-locks"]').each((index, element) => {
    const $element = $(element);
    console.log(lockEditLock($element, $('.content.form')));
  });
});
