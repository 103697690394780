
// import { __ } from './modules/i18n/i18n';
import 'jquery';
// import 'jquery/sort-elements';

export
class AssociationPicker {
  private column_selected: AssociationPickerColumn;

  private column_available: AssociationPickerColumn;

  private _$select: JQuery;

  constructor(private $container: JQuery, private _form_name?: string) {
    this.column_selected = new AssociationPickerColumn(this.$container.find('.column-picked').first(), this, false);
    this.column_available = new AssociationPickerColumn(this.$container.find('.column-available').first(), this, true);

    this.column_available.otherColumn = this.column_selected;
    this.column_selected.otherColumn = this.column_available;

    if (!this._form_name) {
      this._form_name = this.$container.find('select')[0].id;
    }

    this._$select = this.$container.find(`#${this.form_name}`).first();
  }

  get form_name() {
    return this._form_name;
  }

  get $select() {
    return this._$select;
  }
}

class AssociationPickerColumn {
  private $search: JQuery;

  private $elementsContainer: JQuery;

  private $emptyDisplay: JQuery;

  private $hiddenNote: JQuery;

  private $applyAll: JQuery;

  private iOtherColumn: AssociationPickerColumn;

  private hiddenCount = 0;

  constructor(
    private $column: JQuery,
    private container: AssociationPicker,
    private is_available_column: boolean
  ) {
    this.$search = this.$column.find('.element-search > input').first();
    this.$elementsContainer = this.$column.find('.column-elements').first();
    this.$emptyDisplay = this.$column.find('.column-no-element').first();
    this.$hiddenNote = this.$column.find('.column-hidden-note').first();
    this.$applyAll = this.$column.find('.action-apply-all').first();

    this.$search.keyup(() => {
      const searchString = this.$search.val();
      const $elements = this.$elementsContainer.find('.pa-assoc-picker-element');

      this.hiddenCount = 0;

      $elements.removeClass('hidden');
      $elements.each((ii: number, el: HTMLElement) => {
        const $el = $(el);
        const title = $el.find('.title').text().toLowerCase();
        if (title.search(searchString.toLowerCase()) === -1) {
          $el.addClass('hidden');
          this.hiddenCount += 1;
        }
      });
      this.refresh();
    });

    this.$column.on('click', (e: JQueryEventObject) => {
      const $target = $(e.target);
      const $this = $target.closest('.element-action-select');
      if ($this.length === 0) {
        return;
      }
      const $container = $this.parent().parent();
      const clickedId = $container.data('elementId');
      const $clone = $container.clone();
      $clone.find('.element-action-select .fa')
        .removeClass('fa-chevron-right')
        .removeClass('fa-close')
        .addClass(this.is_available_column ? 'fa-close' : 'fa-chevron-right');
      this.iOtherColumn.$elementsContainer.append($clone);
      $container.remove();
      this.iOtherColumn.$elementsContainer.find('.pa-assoc-picker-element')
        .sortElements((a: HTMLElement, b: HTMLElement) => $(a).data('elementName').toString().localeCompare($(b).data('elementName').toString(), 'co-standard', { sensitivity: 'accent' }));

      const $option = this.container.$select.find(`option[value=${clickedId}]`);
      if ($option.attr('selected')) {
        $option.removeAttr('selected');
      } else {
        $option.attr('selected', 'selected');
      }

      this.refresh();
      this.iOtherColumn.refresh();
      e.stopPropagation();
    });

    this.$applyAll.on('click', () => {
      this.$elementsContainer
        .find('.pa-assoc-picker-element:not(.hidden)')
        .find('.element-action-select')
        .click();
      this.refresh();
      this.iOtherColumn.refresh();
    });

    this.refresh();
  }

  set otherColumn(column: AssociationPickerColumn) {
    this.iOtherColumn = column;
  }

  /**
   * Refreshes the column's view.
   *
   * Currently only updates the visibility of the empty message.
   */
  refresh() {
    this.$emptyDisplay.removeClass('hidden');
    const $elements = this.$elementsContainer.find('.pa-assoc-picker-element').filter((_1, element) => !element.classList.contains('hidden'));
    if ($elements.length) {
      this.$emptyDisplay.addClass('hidden');
    }

    if (this.hiddenCount) {
      this.$hiddenNote
        .text(__('{0} Elemente wurden ausgeblendet', [this.hiddenCount]))
        .removeClass('hidden');
    } else {
      this.$hiddenNote
        .text('')
        .addClass('hidden');
    }
  }
}
