
import * as $ from 'jquery';
import * as moment  from 'moment';

export
const nameSystemTime = 'admin-system-info-server-time';

$(($) => {
  if (!$) {
    return;
  }
  const $systemTime = $(`.${nameSystemTime}`);
  if ($systemTime.length === 0) {
    return;
  }

  const systemTime = new Date($systemTime.find('time').attr('datetime'));
  const currentTime = new Date();
  const dateIsUp2Date = moment(systemTime).isBetween(moment(currentTime).subtract(3, 'minutes'), currentTime);
  if (!dateIsUp2Date) {
    $systemTime.addClass('bg-danger');
  }
});
