
// import 'jquery';

// Used on tsservices/index7days
export
class CalendarSwitcher {
    private $form: JQuery;

    private $selectYear: JQuery;

    private $selectWeeks: JQuery;

    private labels: Labels;

    constructor(formSelector: string, labels: Labels) {
      this.$form = $(formSelector);
      this.$selectWeeks = this.$form.find('.week-selector-weeks');
      this.$selectYear = this.$form.find('.week-selector-year');
      this.labels = labels;

      this.$selectYear.change(this.update());
    }

    update() {
      return () => {
        const selectedYear = this.$selectYear.val();
        const elements = this.labels[selectedYear];
        const elementCount = Object.keys(this.labels[selectedYear]).length;

        const options = this.$selectWeeks.find('option');
        const optionsCount = options.length;
        if (optionsCount !== elementCount) {
          if (optionsCount < elementCount) {
            this.$selectWeeks.append('<option value="53"></option>');
          } else {
            this.$selectWeeks.find('option[value="53"]').remove();
          }
        }
        this.$selectWeeks.find('option').toArray().forEach(elem => $(elem).text(elements[$(elem).attr('value')]));
      };
    }
}

type Labels = { [year: string]: {[id: string]: string}; };
