
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

const formatMap: { [key: string]: RegExp } = {
  D: /\bd\b/g,
  DD: /\bdd\b/g,
  DDD: /\bD\b/g,
  DDDD: /\bDDD\b/g,
  d: /\be\b/g,
  ddd: /\beee\b/g,
  dddd: /\beeee\b/g,
  Y: /\by(yyy)?\b/g,
  YY: /\byy\b/g,
  Z: /xxx/g,
  ZZ: /xx/g,
  '[T]': /'T'/g,
};

const formatEx = /[dDjlNSwzWFmMntLoYyaABgGhHisueIOPTZcrU]/g;

function formatPHPBase(format: string) {
  return this.format(momentConvertFormat(format));
};

export
const momentFormatPHP: (phpFormat: string) => string = formatPHPBase.bind(moment);

declare module 'moment' {
  export interface Moment {
    formatLDML: (phpFormat: string) => string;
  }
}

moment.fn.formatLDML = formatPHPBase;

export
function momentConvertFormat(format: string) {
  _.forOwn(formatMap, (search, replaceWith) => format = format.replace(search, replaceWith));
  return format;
};
