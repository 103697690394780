
type PreventNavigation = 'prevent' | 'allow';

/**
 * For storing and retrieving data from uris.
 *
 * Note that pushing new values will only work when you pass window.location.
 */
export
class URIStorage {
  constructor(
    private _uri: Location|string|(() => string),
  ) {}

  /** String representation of the URI. */
  get uri() {
    if (this._uri instanceof Location) {
      return this._uri.toString();
    }

    if (this._uri instanceof Function) {
      return (<() => string> this._uri)();
    }

    return <string> this._uri;
  }

  // TODO: Memoize / Cache?
  /** Read-only access to the query parameters. */
  get query() {
    if (this._uri instanceof Location) {
      return parseQuery((<Location> this._uri).search);
    }

    return parseUrl(this.uri).query;
  }

  get hash() {
    if (this._uri instanceof Location) {
      return parseQuery((<Location> this._uri).hash);
    }

    return parseUrl(this.uri).hash;
  }

  set_hash_params(params: { [key: string]: string }) {
    this.set_generic_params(params, 'hash', 'hash');
  }

  set_query_params(params: { [key: string]: string }) {
    this.set_generic_params(params, 'query', 'search', '?');
  }

  private set_generic_params(params: { [key: string]: string }, read_target_property: 'query' | 'hash', write_target_property: 'hash' | 'search', prefix = '') {
    if (!(this._uri instanceof Location)) {
      throw 'Internal URI is not window.location!';
    }
    const copy = this[read_target_property];
    for (const key in params) {
      copy[key] = params[key];
    }

    (<Location> this._uri)[write_target_property] = prefix + Object.keys(copy).reduce(
      (result, key) => (key == ''
        ? result
        : `${(result != '' ? (`${result}&`) : ('')) + key}=${copy[key]}`),
      '',
    );
  }
}

export
function parseUrl(uri: string) {
  const parser = document.createElement('a');

  parser.href = uri;

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    original_query: parser.search,
    query: parseQuery(parser.search),
    original_hash: parser.hash,
    hash: parseQuery(parser.hash),
  };
}

export
function parseQuery(str: string) {
  const search: { [key: string]: string } = {};

  const queries = str.replace(/^\?/, '').split('&');
  for (const query of queries) {
    const split = query.split('=');
    search[split[0]] = split[1];
  }

  return search;
}

export
const uri_storage = new URIStorage(window.location);

// export default uri_storage;
