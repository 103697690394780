import * as _ from "lodash";

export
interface LivedataPlantData {
    'Ertrag_E day': string,
    'Ertrag_E month': string,
    'Ertrag_E total': string,
    'Ertrag_E year': string,
    'Gesamtanlage_ETA': string,
    'Gesamtanlage_P AC': string,
    'Gesamtanlage_P DC': string,
    'Umweltdaten_Einstrahlung': string,

    [ key: string ]: string,
}

export
interface LivedataNormalizedData {
    'Ertrag_E day': number | '-',
    'Ertrag_E month': number | '-',
    'Ertrag_E total': number | '-',
    'Ertrag_E year': number | '-',
    'Nominal_E day': number | '-',
    'Nominal_E month': number | '-',
    'Nominal_E year': number | '-',
    'Gesamtanlage_ETA': number | '-',
    'Gesamtanlage_P AC': number | '-',
    'Gesamtanlage_P DC': number | '-',
    'Umweltdaten_Einstrahlung': number | '-',

    [ key: string ]: number | '-',
}

export
type MapEntry = Dictionary<{ 'gesamtanlage_de': LivedataPlantData }>;

export
function fetchAuthhash(appkey: string, plant_ids: number[], finito: (data: string) => void) {
    $.get(`/plants-livedata/authhash/${appkey}/${plant_ids.join('|')}`, finito);
}


function stringSafeAdd(num1: number | '-', num2: number | '-') {
  const realNum1 = num1 === '-' ? 0 : num1;
  const realNum2 = num2 === '-' ? 0 : num2;
  return realNum1 + realNum2;
}

function normalizeData(livedataPlantId: number | string, map: MapEntry, kwp?: number | '-'): LivedataNormalizedData|null {
  if (map[livedataPlantId] === undefined) {
    return null;
  }

  if (kwp === undefined) {
    const $plant = $('[data-portal-id="' + livedataPlantId + '"]');
    kwp = float($plant.attr('data-kwp'));
  }

  const entry = map[livedataPlantId].gesamtanlage_de;
  const ertragDay = float(entry['Ertrag_E day']);
  const ertragMonth = float(entry['Ertrag_E month']);
  const ertragTotal = float(entry['Ertrag_E total']);
  const ertragYear = float(entry['Ertrag_E year']);
  const pr = float(entry['Gesamtanlage_ETA']);
  const pAc = float(entry['Gesamtanlage_P AC']);
  const pDc = float(entry['Gesamtanlage_P DC']);
  const u_einstrahlung = float(entry['Umweltdaten_Einstrahlung']);

  /// Normalized / calculated data
  return {
    'Ertrag_E day': ertragDay,
    'Ertrag_E month': ertragMonth,
    'Ertrag_E year': ertragYear,
    'Ertrag_E total': ertragTotal,
    'Nominal_E day': floatDivide(ertragDay, kwp),
    'Nominal_E month': floatDivide(ertragMonth, kwp),
    'Nominal_E year': ertragYear === '-' ? '-' : floatDivide(ertragYear * 1000, kwp),
    'Gesamtanlage_ETA': pr,
    'Gesamtanlage_P AC': pAc,
    'Gesamtanlage_P DC': pDc,
    'Umweltdaten_Einstrahlung': u_einstrahlung,
  };
}

export
class PlantsLivedata {
  private currentAuthhash = '';

  // Lookup table for plants' kWp stats
  public plantsKwps?: Dictionary<number> = undefined;

  constructor(
    private plantIds: number[],
    private endpointWebportal = amtVars.portalAddress,
    private locale = $('html').attr('lang').replace('_', '-'),
    private appkey = 'amtlist',
  ) {
    if (!plantIds.length) {
      console.error('Plant IDs was empty!');
      return;
    }
  }

  startViewCycle() {
    if (!this.plantIds.length) {
      console.error('Plant IDs was empty! Won\'t start view cycle.');
      return;
    }
    fetchAuthhash(this.appkey, this.plantIds, authhash => {
        this.currentAuthhash = authhash;

        setInterval(this.updateAuthhash.bind(this), 5 * 60 * 1000);

        this.dataViewCycle();
        setInterval(this.dataViewCycle.bind(this), 0.5 * 60 * 1000);
    });
  }

    startDataFeed(observer: (data: any) => void) {
      const gh = () => {
        this.fetchData((data: MapEntry) => {
          const map = <MapEntry>_.zipObject(_.map(data, 'id'), data as any);

          Object.keys(map).forEach((id) => {
            let kwp: number|undefined;
            if (this.plantsKwps && this.plantsKwps[id]) {
              kwp = this.plantsKwps[id];
            }
            const normalizedData = normalizeData(id, map, kwp);
            if (!normalizedData) {
              return;
            }
          });

          observer(map);
        });
      };
      fetchAuthhash(this.appkey, this.plantIds, (authhash) => {
        this.currentAuthhash = authhash;

      setInterval(this.updateAuthhash.bind(this), 5 * 60 * 1000);

      gh();
      setInterval(gh, 0.5 * 60 * 1000);
    });
  }

  updateAuthhash() {
    fetchAuthhash(this.appkey, this.plantIds, authhash => {
      this.currentAuthhash = authhash;
    });
  }

  dataViewCycle() {
    this.fetchData(data => {
      const map: MapEntry = _.zipObject(_.map(data, 'id'), data as any);
      console.log(map);
      this.updateView(map);
    });
  }

  fetchData(finito: (data: MapEntry) => void) {
    $.get(`${this.endpointWebportal}/anlage.php?app=${this.appkey}&=aid${this.plantIds.join('|')}&auth=${this.currentAuthhash}`, data => finito(JSON.parse(data)));
  }

  applyData($plant: JQuery, data: LivedataNormalizedData) {
    for (let key in data) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      let value: number | string = data[key];
      if (typeof value === 'number') {
        value = value.toLocaleString(this.locale);
      }
      $plant.find('[data-plant-data-name="' + key + '"]').text(value);
    }
  }

  updateView(map: MapEntry) {
    Object.keys(map).forEach((id) => {
        const $plant = $('[data-portal-id="' + id + '"]');

        const data = normalizeData(id, map);
        if (!data) {
            return;
        }
        this.applyData($plant, data);
    })

    $('[data-plant-childplants]').toArray().forEach((plant: HTMLElement) => {
      const $plant = $(plant);
      const childPlantIds: number[] = $plant.attr('data-plant-childplants')
        .split(' ')
        .map((part: string) => part.replace(/\d+-/, ''))
        .map(parseInt);
      const childPlantsData = childPlantIds
        .map((livedataPlantId) => normalizeData(livedataPlantId, map));
      const kwp = float($plant.attr('data-kwp'));

      function sum(key: string) {
        return childPlantsData.reduce((prevNum: number|'-', current): number | '-' => {
          const currNum: number | '-' = current ? current[key] : 0;
          if (prevNum === '-') {
            return currNum;
          }
          if (currNum === '-') {
            return prevNum;
          }
          return prevNum + currNum;
        }, 0);
      }

      function avg(key: string) {
        const intermediate = sum(key);
        if (intermediate === '-') {
          return '-';
        }
        return intermediate / childPlantsData.length;
      }

      const eDay = sum('Ertrag_E day');
      const eMonth = sum('Ertrag_E month');
      const eYear = sum('Ertrag_E year');

      const data: LivedataNormalizedData = {
        'Ertrag_E day': eDay,
        'Ertrag_E month': eMonth,
        'Ertrag_E total': sum('Ertrag_E total'),
        'Ertrag_E year': eYear,
        'Nominal_E day': floatDivide(eDay, kwp),
        'Nominal_E month': floatDivide(eMonth, kwp),
        'Nominal_E year': eYear === '-' ? '-' : floatDivide(eYear * 1000, kwp),
        'Gesamtanlage_ETA': avg('Gesamtanlage_ETA'),
        'Gesamtanlage_P AC': sum('Gesamtanlage_P AC'),
        'Gesamtanlage_P DC': sum('Gesamtanlage_P DC'),
        'Umweltdaten_Einstrahlung': avg('Umweltdaten_Einstrahlung'),
      };

      this.applyData($plant, data);
    });
  }
}

function float(input: string) {
  if (typeof input === 'number') {
    return input;
  }
  if (typeof input === 'undefined' || input.match(/^-+$/)) {
    return '-';
  }
  return parseFloat(input.replace(',', '.'));
}

function floatDivide(input: number | '-', kwp: number | '-') {
  if (input === '-' || isNaN(input) || kwp === '-' || kwp === 0) {
    return '-';
  }

  return input / kwp;
}

