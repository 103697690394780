
import 'jquery';
import { uri_storage, URIStorage } from '../utils/uri';
import * as _ from 'lodash';

type TriggerState = 'enabled' | 'hidden';

export
type ElementFilterConfig = {
    /** Whether to interprete the global query string parameters. */
    listen_query?: boolean;
    /** Specifies the query key used to retrieve the elements that should be hidden. */
    query_key_hidden?: string;
    /** Specifies the query key used to retrieve the elements that the view should be restricted to. This setting takes precedence over `query_key_hidden`.  */
    query_key_visible?: string;
    /** Whether to use animations. */
    animations?: boolean;
};

/**
 * Controls the display of elements via button toggles and environment input.
 *
 * You initialize this functionality with a container with `data-trigger="element-filter"`.
 * To enable whitelist restrictions, you should set possible element-types
 * via the `data-element-filter-elements` attribute.
 *
 * Within the container, any element with the `data-trigger-element-type`
 * attribute will act as a toggle for the element type denoted in the
 * attribute value.
 *
 * The elements themselves, of which you want to control visibility, should
 * carry the `data-element-type` attribute with its type in the value.
 */
export
class ElementFilter {
  private $trigger_container: JQuery;

  private known_elements: string[];

  private default_options: ElementFilterConfig = {
    listen_query: true,
    query_key_hidden: 'hidden_elements_filter',
    query_key_visible: 'visible_elements_filter',
    animations: true,
  };

  constructor(
      private trigger_container_selector: string,
      private options: ElementFilterConfig = {},
  ) {
    this.options = _.assign(this.default_options, this.options);
    this.$trigger_container = $(trigger_container_selector);
    this.$trigger_container.click(this.click(this));
    console.log(this.$trigger_container);

    const known_elements: string = this.$trigger_container.data('elementFilterElements') || '';
    if (known_elements.length != 0) {
      this.known_elements = known_elements.split(' ').map(str => str.trim());
    }

    if (this.options.listen_query == true) {
      const result = this.apply_query(uri_storage);
      if (result) {
        // This is currently very page-specific
        this.$trigger_container.find('[data-toggle="collapse"]:not([data-target="#filedropper"])').click();
      }
    }
  }

  private click(that: ElementFilter) {
    return function (e: JQueryEventObject) {
      const $target = that.$trigger_container.find(e.target);
      const $trigger = $target.closest('[data-trigger-element-type]');

      if ($trigger.length == 0) {
        return;
      }

      const triggered_element_type = $trigger.data('triggerElementType');
      const trigger_state: TriggerState = <TriggerState>$trigger.attr('data-trigger-element-state') || 'enabled';

      /// if the click target was a checkbox, prevent the checkbox application because we'd basically be doing a double click
      const target_is_checkbox = ($target.attr('type') || '').toLowerCase() == 'checkbox';

      if (trigger_state == 'enabled') {
        that.set_element_state(triggered_element_type, 'hidden');
        if (!target_is_checkbox) {
            $trigger.find('input[type="checkbox"]').prop('checked', false);
        }
      } else {
        that.set_element_state(triggered_element_type, 'enabled');
        if (!target_is_checkbox) {
            $trigger.find('input[type="checkbox"]').prop('checked', true);
        }
      }

      if (!target_is_checkbox) {
          e.preventDefault();
      }
    };
  }

  private set_element_state(element_types: string|string[], trigger_state: TriggerState) {
    this.$trigger_container.find(box(element_types)
      .map(element => `[data-trigger-element-type="${element}"]`).join(', ')).attr('data-trigger-element-state', trigger_state);

    let current_hidden_elements = (this.$trigger_container.attr('data-hidden-elements') || '').split(' ');
    if (trigger_state == 'hidden') {
      current_hidden_elements = current_hidden_elements.concat(box(element_types));
    } else {
      current_hidden_elements = current_hidden_elements.filter(not(in_array(box(element_types))));
    }
    this.$trigger_container.attr('data-hidden-elements', current_hidden_elements.join(' '));
  }

  apply_query(uri: URIStorage) {
    const hidden_elements_string: string = uri.query[this.options.query_key_hidden || ''] || '';
    const visible_elements_string: string = uri.query[this.options.query_key_visible || ''] || '';

    if (hidden_elements_string.length && visible_elements_string.length) {
      console.warn('Both hidden and visible element types have been specified. This is undefined behavior. Only the visible elements filter will be applied.');
    }

    if (visible_elements_string.length) {
      const elements = visible_elements_string.split(' ');
      const hidden_elements = this.known_elements.filter(type => elements.indexOf(type) == -1);
      this.set_element_state(hidden_elements, 'hidden');
      return hidden_elements.length;
    }

    if (hidden_elements_string.length) {
      const elements = hidden_elements_string.split(' ');
      this.set_element_state(elements, 'hidden');
      return elements.length;
    }
  }
}

// Auto-init
// $(() => new ElementFilter('[data-trigger="element-filter"]'));
